/* You can add global styles to this file, and also import other style files */
@import '@woolworthsnz/styleguide/styles';

$basePath: './assets/';

@font-face {
	font-display: swap;
	font-family: 'Fresh';
	font-style: normal;
	font-weight: 500;
	src: url('#{$basePath}fonts/FreshSans-Medium_web.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Fresh';
	font-style: normal;
	font-weight: 700;
	src: url('#{$basePath}fonts/FreshSans-Bold_web.woff2') format('woff2');
}

// temp fix before edr po0int dail refactoring
.edr-dashboard-page {
	$edr-colors: (
		edr-bullet-point: #fd6400,
		edr-font-color: #3a474e,
		edr-boost-bg: #ffefe5,
	);

	.circleChart-background {
		stroke: map-get($edr-colors, edr-boost-bg) !important;
	}

	.circleChart-circle {
		stroke: map-get($edr-colors, edr-bullet-point) !important;
	}

	.circleChart-remaining,
	.circleChart-subline,
	.circleChart-percent {
		fill: map-get($edr-colors, edr-font-color) !important;
	}
}

.cdk-overlay-container .card {
	padding: $spacing-2 !important;

	p {
		font-size: $spacing-3 !important;
	}
}
